import { Box, Grid, Typography } from '@mui/material';
import { formatCurrency } from '@utils/formatCurrency';

const BalanceItem = (props) => {
  const { amount, currencySymbol, title, sxSubtytle1 = {} } = props;

  return (
    <Box sx={{ paddingX: 2.5 }}>
      <Grid container alignItems={'flex-end'} justifyContent={'space-between'}>
        <Grid item>
          <Typography
            variant="subtitle1"
            component="p"
            sx={{ textAlign: 'left', ...sxSubtytle1 }}
          >
            {`${title}:`}
          </Typography>
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Typography
            variant="subtitle2"
            component="p"
            sx={{ textAlign: 'right', paddingLeft: '5px' }}
          >
            {formatCurrency(amount)} {currencySymbol ? currencySymbol : ''}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BalanceItem;
